import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import axios from 'axios';
// import baseUrl from 'https://donora.projectdira.my.id/public/';

function Banner() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [nama, setNama] = useState("");
    const [noHp, setNoHp] = useState("");
    const [alamat, setAlamat] = useState("");
    const [kepentingan, setKepentingan] = useState("");
    const [deskripsi, setDeskripsi] = useState("");

    const [darah, setDarah] = useState([]);
    const [product, setProduct] = useState([]);

    useEffect(() => {
        getDarah();
        getProduct();
    }, []);


    async function getDarah() {
        axios.get(`https://donora.projectdira.my.id/public/api/blood`)
        .then((response) => {
            const allBlood = response.data.data;
            setDarah(allBlood);
        })
        .catch(error => console.log(error));
    }
    console.log(darah);

    async function getProduct() {
        axios.get(`https://donora.projectdira.my.id/public/api/product`)
        .then((response) => {
            const allProduct = response.data.data;
            setProduct(allProduct);
        })
        .catch(error => console.log(error));
    }
    console.log(product);



    async function sendRequestBlood() {

        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };  
        axios.post(`https://donora.projectdira.my.id/public/api/request-blood`, {
            
            name: nama,
            phone_number: noHp,
            necessity: kepentingan,
            address: alamat,
            description: deskripsi,
            blood_id: 1,
            village_id: 1,
            product_id: 13
          }, config)
          .then((response) => {
            console.log(response);
            // navigate(`/transaksi`);
            alert('Ajuan berhasil dikirim');
          }, (error) => {
            console.log(error);
          });
    }

    return (
        <>
        <div className='banner py-5 m-0'>
            <Container>
                <Row>
                    <Col xs={12} md={8}>
                        <h3>Apakah kamu sedang membutuhkan bantuan terkait donor darah?</h3>
                    </Col>
                    <Col xs={12} md={4}>
                        <button onClick={handleShow} className='py-2 px-4 mt-2'>Ajukan Darah<i className="bi bi-arrow-right-circle-fill ps-3"></i></button>
                    </Col>
                </Row>
            </Container>
        </div>


        {/* FORM MODAL */}
        <Modal
            size='xs'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='modal'
        >
            <Modal.Header closeButton>
            <Modal.Title className='modal-title py-2 px-3'><h4>Ajukan Permintaan darah</h4></Modal.Title>
            
            </Modal.Header>
            <Modal.Body>
            <small className='mx-2 mt-2 text-danger'>Pastikan darah tidak ada di stock</small>
                <Form>
                    <Form.Group className="my-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nama</Form.Label>
                        <Form.Control
                        type="text"
                        className='form-control'
                        onChange={(e) => setNama(e.target.value)} 
                        value={nama}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>No. HP</Form.Label>
                        <Form.Control
                        type="text"
                        className='form-control'
                        onChange={(e) => setNoHp(e.target.value)} 
                        value={noHp}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Provinsi</Form.Label>
                        <Form.Select aria-label="Default select example" className='form-control'>
                            <option value="1">Jawa Timur</option>
                            <option value="2">Jawa Barat</option>
                            <option value="3">Jawa Tengah</option>
                            <option value="4">Jogjakarta</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Kabupaten/Kota</Form.Label>
                        <Form.Select aria-label="Default select example" className='form-control'>
                            <option value="1">Surabaya</option>
                            <option value="2">Sidoarjo</option>
                            <option value="3">Gresik</option>
                            <option value="4">Tuban</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Kecamatan</Form.Label>
                        <Form.Select aria-label="Default select example" className='form-control'>
                            <option value="1">Wonokromo</option>
                            <option value="2">Gubeng</option>
                            <option value="3">Rungkut</option>
                            <option value="4">Gunung Anyar</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Kelurahan</Form.Label>
                        <Form.Select aria-label="Default select example" className='form-control'>
                            <option value="1">Wonocolo (6243)</option>
                            <option value="2">Gayungan (6202)</option>
                            <option value="3">Mulyorejo (6293)</option>
                            <option value="4">Manyar Sabrangan (6223)</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="my-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Alamat Lengkap (Perumahan, No. Rumah / RT/ RW)</Form.Label>
                        <Form.Control type="text"
                        className='form-control'
                        onChange={(e) => setAlamat(e.target.value)} 
                        value={alamat}/>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Golongan darah yang dibutuhkan</Form.Label>
                        <Form.Select aria-label="Default select example" className='form-control'>
                            <option>Pilih Golongan Darah</option>
                            {darah.map((b) => (
                                <option value="{b.id}" key={b.id}>{b.blood_type} {b.rhesus}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Produk darah yang dibutuhkan</Form.Label>
                        <Form.Select aria-label="Default select example" className='form-control'>
                            <option>Plasma</option>
                            {product.map((p) => (
                                <option value="{p.id}" key={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Kepentingan</Form.Label>
                        <Form.Control type="text"
                        className='form-control'
                        onChange={(e) => setKepentingan(e.target.value)} 
                        value={kepentingan} as="textarea" rows={2} />
                    </Form.Group>
                    <Form.Group className="mb-3 px-4" controlId="exampleForm.ControlInput1">
                        <Form.Label>Deskripsi</Form.Label>
                        <Form.Control type="text"
                        className='form-control'
                        onChange={(e) => setDeskripsi(e.target.value)} 
                        value={deskripsi} as="textarea" rows={3} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" onClick={sendRequestBlood} className='btn btn-primary'>Ajukan</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default Banner;