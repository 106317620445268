import { Container, Row, Col, Card } from 'react-bootstrap';

function About() {
    return (
        <>
        <div className='about py-4' id='about'>
            <Container>
                <h3 className='mt-4'>Tentang Kami</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                <Row xs={1} md={3} className="g-4 card-about">
                    <Col>
                        <Card className='py-4 px-3 text-center card-item'>
                        <img src='ajuan-darah.png'/>
                        <Card.Header className='card-title'>Pengajuan Darah</Card.Header>
                        <Card.Body>
                            <Card.Text className='text-center'>
                                Pengguna dapat mengajukan permintaan darah sesuai kebutuhan
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='py-4 px-3 text-center card-item'>
                        <img src='Notification.png'/>
                        <Card.Header className='card-title'>Notifikasi Darurat</Card.Header>
                        <Card.Body>
                            <Card.Text className='text-center'>
                                Dapatkan notifikasi terkait ajuan darah, status, reward dan lainnya
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='py-4 px-3 text-center card-item'>
                        <img src='cek-stock.png'/>
                        <Card.Header className='card-title'>Cek Stock Darah Realtime</Card.Header>
                        <Card.Body>
                            <Card.Text className='text-center'>
                                Cari kebutuhan stock darah secara real time
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='py-4 px-3 text-center card-item'>
                        <img src='riwayat-donor.png'/>
                        <Card.Header className='card-title'>Riwayat Donor</Card.Header>
                        <Card.Body>
                            <Card.Text className='text-center'>
                                Lacak riwayat donor dan pengingat donor selanjutnya
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='py-4 px-3 text-center card-item'>
                        <img src='poin.png'/>
                        <Card.Header className='card-title'>Poin</Card.Header>
                        <Card.Body>
                            <Card.Text className='text-center'>
                                Dapatkan poin setiap melakukan donor darah
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='py-4 px-3 text-center card-item'>
                        <img src='hadiah.png'/>
                        <Card.Header className='card-title'>Tukarkan Hadiah</Card.Header>
                        <Card.Body>
                            <Card.Text className='text-center'>
                                Tukar poin dengan berbagai hadiah dan keuntungan lainnya
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
                
                
                
                
                
                
                
                
                
                {/* <Row className='text-center card-about g-4'>
                    <Col xs={12} md={4} className='card-item'>
                        <Col className='card-item'>
                            <img src='ajuan-darah.png' className='d-block text-center'></img>
                            <h5>Pengajuan Darah</h5>
                            <p>Pengguna dapat mengajukan permintaan darah sesuai kebutuhan</p>
                        </Col>
                    </Col>
                    <Col xs={12} md={4} className='card-item'>
                        <img src='Notification.png' className='d-block text-center'></img>
                        <h5>Notifikasi Darurat</h5>
                        <p>Dapatkan notifikasi terkait ajuan darah, status, reward dan lainnya</p>
                    </Col>
                    <Col xs={12} md={4} className='card-item'>
                        <img src='cek-stock.png' className='d-block text-center'></img>
                        <h5>Cek Stock Darah Realtime</h5>
                        <p>Cari kebutuhan stock darah secara real time</p>
                    </Col>
                    <Col xs={12} md={4} className='card-item'>
                        <img src='riwayat-donor.png' className='d-block text-center'></img>
                        <h5>Riwayat Donor</h5>
                        <p>Lacak riwayat donor dan pengingat donor selanjutnya</p>
                    </Col>
                    <Col xs={12} md={4} className='card-item'>
                        <img src='poin.png' className='d-block text-center'></img>
                        <h5>Poin</h5>
                        <p>Dapatkan poin setiap melakukan donor darah</p>
                    </Col>
                    <Col xs={12} md={4} className='card-item'>
                        <img src='hadiah.png' className='d-block text-center'></img>
                        <h5>Tukarkan Hadiah</h5>
                        <p>Tukar poin dengan berbagai hadiah dan keuntungan lainnya</p>
                    </Col>
                </Row> */}
                {/* <Row xs={1} md={3} className="g-4 card-about">
                    {Array.from({ length: 6 }).map((_, idx) => (
                        <Col key={idx}>
                        <Card className='py-4 px-3 text-center'>
                            <Card.Body>
                            <img src='notification.png' className='d-block text-center'></img>
                            <Card.Title className='card-title pb-3'>
                                <h5>Notifikasi untuk Donor Darah Selanjutnya</h5>
                            </Card.Title>
                            <Card.Text className='text-center'>
                                This is a longer card with supporting text below as a natural
                                lead-in to additional content. This content is a little bit
                                longer.
                            </Card.Text>
                            </Card.Body>
                        </Card>
                        </Col>
                    ))}
                </Row> */}

                {/* PROSES PENGAJUAN DARAH */}
                <h3 className='pt-5 pb-3 text-start'>Proses Pengajuan <span className='d-block'>Darah</span></h3>
                <Row xs={2} md={3} className="g-5 card-proses">
                    <Col>
                        <i className="bi bi-1-circle-fill satu"></i>
                        <h5 className='py-1'>Memilih menu "Ajukan Darah"</h5>
                        <p className='text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </Col>
                    <Col>
                        <i className="bi bi-2-circle-fill dua"></i>
                        <h5 className='py-1'>Isi Formulir</h5>
                        <p className='text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </Col>
                    <Col>
                        <i className="bi bi-3-circle-fill tiga"></i>
                        <h5 className='py-1'>Ajuan Diproses</h5>
                        <p className='text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </Col>
                    <Col>
                        <i className="bi bi-4-circle-fill empat"></i>
                        <h5 className='py-1'>Ada yang bersedia</h5>
                        <p className='text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </Col>
                    <Col>
                        <i className="bi bi-5-circle-fill lima"></i>
                        <h5 className='py-1'>Kami akan menghubungimu</h5>
                        <p className='text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </Col>
                    <Col>
                        <i className="bi bi-6-circle-fill satu"></i>
                        <h5 className='py-1'>Cek Status Ajuan Darah</h5>
                        <p className='text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default About;