import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
    return (
        <>
        <div className='footer py-2'>
            <Container>
                <Row className='konten-footer'>
                    <Col xs={12} md={6}>
                        <img src='donora_white.png'/>
                        <Row className='social-footer mt-4 pt-2'>
                            <Col xs={2} md={1} className='me-3 footer-icon'>
                                <a href='https://www.instagram.com/otakkanansby/'><i className="p-3 bi bi-instagram"></i></a>
                            </Col>
                            <Col xs={2} md={1} className='me-3 footer-icon'>
                                <a href='https://www.tiktok.com/@otakkanansby?_t=8cviVNDP0L3&_r=1'><i className="p-3 bi bi-tiktok"></i></a>
                            </Col>
                            <Col xs={2} md={1} className='me-3 footer-icon'>
                                <a href='https://www.linkedin.com/company/otakkanan/mycompany/'><i className="p-3 bi bi-linkedin"></i></a>
                            </Col>
                            <Col xs={2} md={1} className='me-3 footer-icon'>
                                <a href='mailto:wahyu@otak.kanan.co.id'><i className="p-3 bi bi-envelope-at-fill"></i></a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} className='right-side'>
                        <h5>PT. Otak Kanan</h5>
                        <p className='mb-5'>Graha Pena Building 15th Floor Suite 1503, Jl. A. Yani No.88,<span className='d-block'>Surabaya, Indonesia 60234</span></p>
                        <Row>
                            <Col xs={6} md={6}>
                                <h6>Products</h6>
                                <p>Syarat dan Ketentuan</p>
                            </Col>
                            <Col xs={6} md={6}>
                                <h6>Company</h6>
                                <p>Tentang Kami</p>
                                <p>Contact</p>
                            </Col>
                        </Row>
                    </Col>
                    <hr></hr>
                    <p className='copyright'>Copyright 2023 | <span> PT. Otak Kanan</span></p>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default Footer;