import { Container, Row, Col } from 'react-bootstrap';

function BannerDownload() {
    return (
        <>
        <Container fluid>
        <div className='download-banner'>
            <img src='banner_download.png'/>
        </div>
        </Container>

        {/* <div className='download-banner'>
            <Container fluid>
            <Row>
                <Col xs={12} md={12} className='dewa-darah pt-5 mt-5'>
                    <img src='img_playstore.png'/>
                    <h3 className='d-inline'>Dapatkan Title <span>Dewa Darahmu</span> Sekarang!</h3>
                </Col>
                <Col xs={12} md={12} className='bg-red-banner'>
                    <h3>Unduh Aplikasi donora di Playstore</h3>
                    <a href='#'><img src='button-downloadApp.png' className='download-app my-3'/></a>
                </Col>
            </Row>
            </Container>
        </div> */}
        {/* <div className='banner-downdload py-5 text-white'>
            <Container>
                <Row>
                    <Col xs={12} md={12}>
                        <Row>
                            <Col xs={12} md={12}>
                                <img src='img_playstore.png'/>
                                <h3 className='d-inline'>Dapatkan Title Dewa Darahmu Sekarang!</h3>
                            </Col>
                            <Col xs={12} md={12} className='bg-red-banner'>
                                <h3>Unduh Aplikasi donora di Playstore !</h3>
                                <a href='#'><img src='button-downloadApp.png' className='download-app'/></a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div> */}
        </>
    )
}

export default BannerDownload;