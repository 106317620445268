import './asset/style-laptop.css';
import './asset/style-mobile.css';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Banner from './components/Banner';
import About from './components/About';
import Stock from './components/Stock';
import BannerDownload from './components/BannerDownload';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
       <Navbar/>
       <Hero/>
       <Banner/>
       <About/>
       <Stock/>
       <BannerDownload/>
       <Footer/>
    </div>
  );
}

export default App;
