import { Container, Row, Col } from 'react-bootstrap';

function Hero() {
    return (
        <>
        <div className='hero-section' id='home'>
            <Container>
                <Row>
                    <Col md={5} xs={{ order: 'last' }}>
                        <img src='img_landing.png' alt='donor' className='img-landing-page'/>
                    </Col>
                    <Col md={{ order: 'last' }} xs={{ order: 'first' }} className='hero-text'>
                        <h1>Darahmu selamatkan nyawa! Donor darah, aksi kebaikan yang tak ternilai harganya</h1>
                        <p>Berikan kesempatan bagi mereka yang membutuhkan dengan menyumbangkan darahmu melalui aplikasi Donora. Bergabunglah dengan ribuan orang yang telah menyelamatkan nyawa melalui aksi kebaikan ini. Sederhana, mudah, dan bermanfaat bagi banyak orang.</p>
                        <a href='#'><img src='button-downloadApp.png'/></a>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default Hero;