import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';

function Stock() {

    const data = [
    {name: 'A+', count: 400},
    {name: 'B+', count: 700},
    {name: 'AB+', count: 200},
    {name: 'O+', count: 1000},
    {name: 'A-', count: 400},
    {name: 'B-', count: 700},
    {name: 'AB-', count: 200},
    {name: 'O-', count: 1000}
    ];
    return (
        <>
        <br></br>
        <div className='stock py-4' id='stock'>
            <Container>
                <h3>Stock darah</h3>
                <Form>
                    <Form.Group className="my-4" controlId="formBasicEmail">
                        <Row>
                            <Col>
                                <Form.Control type="text" placeholder="Cari Kota/Kab"/>
                            </Col>
                            <Col>
                                <Button variant="primary" type="submit" className='d-inline'>
                                    <strong><i className="bi bi-search"></i></strong> Cari
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
                <Row>
                    <Col xs={12} md={6}>
                        
                        <Table striped>
                            <thead>
                                <tr>
                                <th>Kode Pos</th>
                                <th>Nama Kecamatan</th>
                                <th>Stock Darah (kantong)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: 10 }).map((_, idx) => (
                                    <tr key={idx}>
                                    <td>6209</td>
                                    <td>Dukuh Pakis</td>
                                    <td><strong>400</strong></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} md={6}>
                        <BarChart width={350} height={400} data={data} className='mt-5 bar-chart'>
                            <Bar dataKey="count" fill="#D80032" />
                            <XAxis dataKey="name" />
                            <YAxis />
                        </BarChart>
                    </Col>
                </Row>
                
            </Container>
        </div>
        </>
    )
}

export default Stock;

// 